<template>
  <div>
    <!-- Stats Payments -->
    <div class="title pb-1">Payments [metric:sum]</div>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          :statistic="Number(data.today_deposits).toLocaleString()"
          statistic-title="Today Deposits"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          color="success"
          :statistic="Number(data.total_deposits).toLocaleString()"
          statistic-title="Total Deposits"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          color="info"
          :statistic="Number(data.today_payouts).toLocaleString()"
          statistic-title="Withdrawn Today"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="DollarSignIcon"
          color="warning"

          :statistic="Number(data.total_payouts).toLocaleString()"
          statistic-title="Total Withdrawn"
        />
      </b-col>
    </b-row>

    <div class="title pb-1">Bets [metric:count]</div>
      <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="LayersIcon"
          color="primary"

          :statistic="Number(data.total_bets).toLocaleString()"
          statistic-title="All Bets"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-horizontal
          icon="GiftIcon"
          color="success"

          :statistic="Number(data.won_bets).toLocaleString()"
          statistic-title="Won Bets"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="MehIcon"
          color="info"

          :statistic="Number(data.lost_bets).toLocaleString()"
          statistic-title="Lost Bets"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="XIcon"
          color="warning"

          :statistic="Number(data.cancelled_bets).toLocaleString()"
          statistic-title="Cancelled Bets"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="LayersIcon"
          color="secondary"

          :statistic="Number(data.placed_bets).toLocaleString()"
          statistic-title="Placed Bets"
        />
      </b-col>
    </b-row>
    
    <div class="title pb-1">Users [metric:count]</div>
      <b-row>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="primary"

          :statistic="Number(data.today_users).toLocaleString()"
          statistic-title="Joined Today"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="success"

          :statistic="Number(data.active_today).toLocaleString()"
          statistic-title="Active Today"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="info"

          :statistic="Number(data.total_users).toLocaleString()"
          statistic-title="All Users"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="warning"

          :statistic="Number(data.active_users).toLocaleString()"
          statistic-title="Active Users"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="info"
          :statistic="Number(data.verified_users).toLocaleString()"
          statistic-title="With Deposits"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UsersIcon"
          color="info"
          :statistic="Number(data.dormant_users).toLocaleString()"
          statistic-title="Dormant Users"
        />
      </b-col>
    </b-row>
    <div class="title pb-1">wallets [metric:sum]</div>
      <b-row>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CircleIcon"
          color="primary"

          :statistic="Number(data.total_cash_balances).toLocaleString()"
          statistic-title="Total Cash Balances"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          icon="CircleIcon"
          color="secondary"

          :statistic="Number(data.total_bonus_balances).toLocaleString()"
          statistic-title="Total Bonus Balances"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import { $themeColors } from '@themeConfig'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ChartjsBarChart from './ChartjsBarChart.vue'
import PayinPerMonth from './PayinPerMonth.vue'

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#8bc310',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  latestBarChartPrimary: '#666ee8',
  latestBarChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BTab,
    BTabs,

    flatPickr,
    StatisticCardHorizontal,
    ChartjsBarChart,
    PayinPerMonth,
  },
  data() {
    return {
      rangePicker: null,
      config: {
        mode: 'range',
      },
      userData: JSON.parse(localStorage.getItem('userData')),
      data: {
        total_deposits: 0,
        today_deposits: 0,
        total_payouts: 0,
        today_payouts: 0,
        total_stakes:0,
        total_winnings:0,
        won_bets:0,
        lost_bets:0,
        cancelled_bets:0,
        placed_bets:0,
        total_bets:0,
        today_users: 0,
        total_users: 0,
        active_users: 0,
        active_today: 0,
        verified_users: 0,
        dormant_users: 0,
        total_bonus_balances: 0,
        total_cash_balances: 0,
      },
      loaded: false,
      payinsBarChart: {
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: chartColors.successColorShade,
              borderColor: 'transparent',
            },
          ],
        },
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'bottom',
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                scaleLabel: {
                  display: false,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  stepSize: 15000,
                  min: 0,
                  max: 200000,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
      },
    }
  },
  mounted() {
    // payments
    this.$http.get('/stats/summary/')
      .then(response => {
        this.data = response.data.data
        // this.payinsBarChart.data.labels = JSON.parse(JSON.stringify(response.data.graph_data.payin_labels))
        // this.payinsBarChart.data.datasets[0].data = JSON.parse(JSON.stringify(response.data.graph_data.payins_graph_data))
        this.loaded = true
      })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
